<template>
  <b-row>
    <b-col>
      <g-chart-custom
        ref="chart1L"
        title="In-chain distribution"
        :subtitle="charts.chart1L.subtitle"
        type="ColumnChart"
        :loading="charts.chart1L.loading"
        :data="charts.chart1L.chartData"
        :options="charts.chart1L.chartOptions"
        @chart-element-selected="drilldownChart1L($event, parentRef)"
      >
        <template #legend>
          <b-popover target="chart1L-ddi" triggers="hover" placement="right">
            <span>Chart drilldown available</span>
          </b-popover>
          <i id="chart1L-ddi" class="ml-2 fa fa-share fa-rotate-90" />
        </template>
      </g-chart-custom>
    </b-col>
    <b-col>
      <g-chart-custom
        ref="chart1R"
        title="In-chain distribution"
        :subtitle="charts.chart1R.subtitle"
        type="ColumnChart"
        :loading="charts.chart1R.loading"
        :data="charts.chart1R.chartData"
        :options="charts.chart1R.chartOptions"
        @chart-element-selected="drilldownChart1R($event, parentRef)"
      >
        <template #legend>
          <b-popover target="chart1L-ddi" triggers="hover" placement="right">
            <span>Chart drilldown available</span>
          </b-popover>
          <i id="chart1R-ddi" class="ml-2 fa fa-share fa-rotate-90" />
        </template>
      </g-chart-custom>
    </b-col>
  </b-row>
</template>

<script>
import GChartCustom from '@/components/GChartCustom'

import charts from './charts'
import charts1LRMethods from './charts1LR.methods'

export default {
  props: {
    parentRef: Object
  },
  components: {
    GChartCustom
  },
  data () {
    return {
      charts
    }
  },
  mounted () {},
  methods: {
    ...charts1LRMethods
  }
}
</script>
