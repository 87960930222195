import moment from 'moment'

export default {
  drawChart3L: function (response, filter) {
    if (response.length === 0) return

    this.charts.chart3L.subtitle = `Category share of all sales in the period [${moment(
      filter.period.startDate
    ).format('YYYY-MM-DD')} - ${moment(filter.period.endDate).format(
      'YYYY-MM-DD'
    )}]`

    let headers = [
      [
        'Year',
        'Whiskey',
        'Gin',
        'Liqueur',
        'Vodka',
        'Specialty',
        'White Whiskey',
        'Rum'
      ]
    ]

    let data = [
      ...response.map(item => {
        return [
          new Date(moment(item.date).format('MM/DD/YY')),
          parseFloat(item.whiskeysales || 0),
          parseFloat(item.ginsales || 0),
          parseFloat(item.liqueursales || 0),
          parseFloat(item.vodkasales || 0),
          parseFloat(item.specialtysales || 0),
          parseFloat(item.whitewhiskeysales || 0),
          parseFloat(item.rumsales || 0)
        ]
      })
    ]

    // let pivot_data = this.$helpers.getPivotArray(data, 0, 2, 1, false)
    // this.charts.chart3L.chartData = pivot_data;

    this.charts.chart3L.chartData = headers.concat(data)
  },

  drilldownChart3L: function (e, parentRef) {
    //let filter = this.$refs.filteringPanel.selected
    let filter = JSON.parse(JSON.stringify(parentRef.filteringPanel.selected))

    //get selected chart elements
    let date = e.data[e.selection.row + 1][0]

    filter.period.startDate = date
    filter.period.endDate = moment(date).endOf('month')

    //filter.period.startDate = moment(date).startOf('year')
    //filter.period.endDate =  moment(date).endOf('year')

    let description = `${this.$refs.chart3L.title} [Period: ${moment(
      date
    ).format('MM/DD/YY')}]`

    this.$store.dispatch('drilldown.save', {
      filter: filter,
      parent: this.$route.name,
      description: description,
      method: 'ChainPerformance.Details'
    })

    //open new page
    this.$router.push({
      name: 'Chart drilldown'
    })
  },

  drawChart3R: function (response, filter) {
    if (response.length === 0) return

    this.charts.chart3R.subtitle = `Category share of all sales in the period [${moment(
      filter.period.startDate
    ).format('YYYY-MM-DD')} - ${moment(filter.period.endDate).format(
      'YYYY-MM-DD'
    )}]`

    let headers = [
      [
        'Year',
        'Whiskey',
        'Gin',
        'Liqueur',
        'Vodka',
        'Specialty',
        'White Whiskey',
        'Rum'
      ]
    ]

    let data = [
      ...response.map(item => {
        return [
          new Date(moment(item.date).format('MM/DD/YY')),
          parseFloat(item.whiskeysales || 0),
          parseFloat(item.ginsales || 0),
          parseFloat(item.liqueursales || 0),
          parseFloat(item.vodkasales || 0),
          parseFloat(item.specialtysales || 0),
          parseFloat(item.whitewhiskeysales || 0),
          parseFloat(item.rumsales || 0)
        ]
      })
    ]

    // let pivot_data = this.$helpers.getPivotArray(data, 0, 2, 1, false)
    // this.charts.chart3L.chartData = pivot_data;

    this.charts.chart3R.chartData = headers.concat(data)
  },

  drilldownChart3R: function (e, parentRef) {
    //let filter = this.$refs.filteringPanel.selected
    let filter = JSON.parse(JSON.stringify(parentRef.filteringPanel.selected))

    //get selected chart elements
    let date = e.data[e.selection.row + 1][0]

    filter.period.startDate = date
    filter.period.endDate = moment(date).endOf('month')

    //filter.period.startDate = moment(date).startOf('year')
    //filter.period.endDate =  moment(date).endOf('year')

    let description = `${this.$refs.chart3R.title} [Period: ${moment(
      date
    ).format('MM/DD/YY')}]`

    this.$store.dispatch('drilldown.save', {
      filter: filter,
      parent: this.$route.name,
      description: description,
      method: 'ChainPerformance.Details'
    })

    //open new page
    this.$router.push({
      name: 'Chart drilldown'
    })
  }
}
