<template>
  <b-row>
    <b-col>
      <g-chart-custom
        ref="chart6L"
        :default-mode="charts.chart6L.isDefaultMode"
        :title="`Chains sales`"
        :subtitle="charts.chart6L.subtitle"
        :type="charts.chart6L.type"
        :loading="charts.chart6L.loading"
        :data="charts.chart6L.chartData"
        :options="charts.chart6L.chartOptions"
      >
        <template #legend>
          <i id="chart6L-help" class="fa fa-question-circle" />
          <b-popover target="chart6L-help" triggers="hover" placement="right">
            <span v-html="popoverTemplate" />
          </b-popover>
        </template>
        <template #chartTopBar>
          <b-row align-h="between">
            <b-col lg="6" sm="12" md="12" v-show="charts.chart6L.isDefaultMode">
              <b-pagination
                v-model="charts.chart6L.pagination.value"
                pills
                :hide-ellipsis="true"
                :total-rows="charts.chart6L.pagination.total"
                :per-page="10"
                @input="updateChart6L"
              />
            </b-col>
            <b-col class="text-right">
              <b-button-group>
                <b-button
                  class="mb-2"
                  variant="outline-primary"
                  @click="
                    charts.chart6L.isColumnType = false
                    charts.chart6L.type = 'PieChart'
                    charts.chart6L.isDefaultMode = true
                  "
                >
                  <font-awesome-icon icon="chart-pie" />
                </b-button>
                <b-button
                  class="mb-2"
                  variant="outline-primary"
                  @click="
                    charts.chart6L.isColumnType = true
                    charts.chart6L.type = $isMobile ? 'ColumnChart' : 'BarChart'
                    charts.chart6L.isDefaultMode = true
                  "
                >
                  <font-awesome-icon icon="chart-bar" />
                </b-button>
                <b-button
                  class="mb-2"
                  variant="outline-primary"
                  @click="charts.chart6L.isDefaultMode = false"
                >
                  <font-awesome-icon icon="list" />
                </b-button>
              </b-button-group>
            </b-col>
          </b-row>
        </template>
        <template #table>
          <table-custom
            :name="chart6LTable.name"
            :loading="chart6LTable.isLoading"
            :data="chart6LTable.dataSet"
            :options="chart6LTable.options"
            @mounted="onChart6LTableMount"
          />
        </template>
        <template #chartBottomBar>
          <b-row align-h="between" v-show="charts.chart6L.isDefaultMode">
            <b-col class="text-left">
              <strong>Total sold:</strong> ${{
                charts.chart6L.totals.totalSold.toLocaleString(
                  'en-US',
                  'currency'
                )
              }}
              <br />
              <strong>Total sold selection:</strong> ${{
                charts.chart6L.totals.totalSoldSelection.toLocaleString(
                  'en-US',
                  'currency'
                )
              }}
            </b-col>
          </b-row>
        </template>
      </g-chart-custom>
    </b-col>

    <b-col>
      <g-chart-custom
        ref="chart6R"
        :default-mode="charts.chart6R.isDefaultMode"
        :title="`Chains sales`"
        :subtitle="charts.chart6R.subtitle"
        :type="charts.chart6R.type"
        :loading="charts.chart6R.loading"
        :data="charts.chart6R.chartData"
        :options="charts.chart6R.chartOptions"
      >
        <template #legend>
          <i id="chart6R-help" class="fa fa-question-circle" />
          <b-popover target="chart6R-help" triggers="hover" placement="right">
            <span v-html="popoverTemplate" />
          </b-popover>
        </template>
        <template #chartTopBar>
          <b-row align-h="between">
            <b-col lg="6" sm="12" md="12" v-show="charts.chart6R.isDefaultMode">
              <b-pagination
                v-model="charts.chart6R.pagination.value"
                pills
                :hide-ellipsis="true"
                :total-rows="charts.chart6R.pagination.total"
                :per-page="10"
                @input="updateChart6R"
              />
            </b-col>
            <b-col class="text-right">
              <b-button-group>
                <b-button
                  class="mb-2"
                  variant="outline-primary"
                  @click="
                    charts.chart6R.isColumnType = false
                    charts.chart6R.type = 'PieChart'
                    charts.chart6R.isDefaultMode = true
                  "
                >
                  <font-awesome-icon icon="chart-pie" />
                </b-button>
                <b-button
                  class="mb-2"
                  variant="outline-primary"
                  @click="
                    charts.chart6R.isColumnType = true
                    charts.chart6R.type = $isMobile ? 'ColumnChart' : 'BarChart'
                    charts.chart6R.isDefaultMode = true
                  "
                >
                  <font-awesome-icon icon="chart-bar" />
                </b-button>
                <b-button
                  class="mb-2"
                  variant="outline-primary"
                  @click="charts.chart6R.isDefaultMode = false"
                >
                  <font-awesome-icon icon="list" />
                </b-button>
              </b-button-group>
            </b-col>
          </b-row>
        </template>
        <template #table>
          <table-custom
            :name="chart6RTable.name"
            :loading="chart6RTable.isLoading"
            :data="chart6RTable.dataSet"
            :options="chart6RTable.options"
            @mounted="onChart6RTableMount"
          />
        </template>
        <template #chartBottomBar>
          <b-row align-h="between" v-show="charts.chart6R.isDefaultMode">
            <b-col class="text-left">
              <strong>Total sold:</strong> ${{
                charts.chart6R.totals.totalSold.toLocaleString(
                  'en-US',
                  'currency'
                )
              }}
              <br />
              <strong>Total sold selection:</strong> ${{
                charts.chart6R.totals.totalSoldSelection.toLocaleString(
                  'en-US',
                  'currency'
                )
              }}
            </b-col>
          </b-row>
        </template>
      </g-chart-custom>
    </b-col>
  </b-row>
</template>

<script>
import GChartCustom from '@/components/GChartCustom'
import TableCustom from '@/components/TableCustom'

import charts from './charts'
import charts6LRMethods from './charts6LR.methods'
import tables from './tables'

export default {
  components: {
    GChartCustom,
    TableCustom
  },
  data () {
    return {
      charts,
      popoverTemplate:
        '<ul> <li>Selection period: The user selected date range</li> </ul>',
      chart6LTable: tables.chart6LTable,
      chart6RTable: tables.chart6RTable
    }
  },
  methods: {
    ...charts6LRMethods
  }
}
</script>
