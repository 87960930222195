<template>
  <b-row>
    <b-col>
      <g-chart-custom
        ref="chart3L"
        title="In-chain product mix"
        :subtitle="charts.chart3L.subtitle"
        type="AreaChart"
        :loading="charts.chart3L.loading"
        :data="charts.chart3L.chartData"
        :options="charts.chart3L.chartOptions"
        @chart-element-selected="drilldownChart3L($event, parentRef)"
      >
        <template #legend>
          <b-popover target="chart3L-ddi" triggers="hover" placement="right">
            <span>Chart drilldown available</span>
          </b-popover>
          <i id="chart3L-ddi" class="ml-2 fa fa-share fa-rotate-90" />
        </template>
      </g-chart-custom>
    </b-col>
    <b-col>
      <g-chart-custom
        ref="chart3R"
        title="In-chain product mix"
        :subtitle="charts.chart3R.subtitle"
        type="AreaChart"
        :loading="charts.chart3R.loading"
        :data="charts.chart3R.chartData"
        :options="charts.chart3R.chartOptions"
        @chart-element-selected="drilldownChart3R($event, parentRef)"
      >
        <template #legend>
          <b-popover target="chart3R-ddi" triggers="hover" placement="right">
            <span>Chart drilldown available</span>
          </b-popover>
          <i id="chart3R-ddi" class="ml-2 fa fa-share fa-rotate-90" />
        </template>
      </g-chart-custom>
    </b-col>
  </b-row>
</template>

<script>
import GChartCustom from '@/components/GChartCustom'

import charts from './charts'
import charts3LRMethods from './charts3LR.methods'

export default {
  props: {
    parentRef: Object
  },
  components: {
    GChartCustom
  },
  data () {
    return {
      charts
    }
  },
  methods: {
    ...charts3LRMethods
  }
}
</script>
