import moment from 'moment'

export default {
  drawChart2L: function (response, filter) {
    if (response.length === 0) return

    this.charts.chart2L.subtitle = `$USD spend in the period [${moment(
      filter.period.startDate
    ).format('YYYY-MM-DD')} - ${moment(filter.period.endDate).format(
      'YYYY-MM-DD'
    )}]`

    //chart 2
    let headers = [['Year', 'Total Sales', 'Average Sales']]

    let data = [
      ...response.map(item => {
        return [
          new Date(moment(item.date).format('MM/DD/YY')),
          parseFloat(item.totalsales || 0),
          parseFloat(item.averagesales || 0)
        ]
      })
    ]

    this.charts.chart2L.chartData = headers.concat(data)
  },
  drawChart2R: function (response, filter) {
    if (response.length === 0) return

    this.charts.chart2R.subtitle = `$USD spend in the period [${moment(
      filter.period.startDate
    ).format('YYYY-MM-DD')} - ${moment(filter.period.endDate).format(
      'YYYY-MM-DD'
    )}]`

    //chart 2
    let headers = [['Year', 'Total Sales', 'Average Sales']]

    let data = [
      ...response.map(item => {
        return [
          new Date(moment(item.date).format('MM/DD/YY')),
          parseFloat(item.totalsales || 0),
          parseFloat(item.averagesales || 0)
        ]
      })
    ]

    this.charts.chart2R.chartData = headers.concat(data)
  },
  drilldownChart2L: function (e, parentRef) {
    //let filter = this.$refs.filteringPanel.selected
    let filter = JSON.parse(JSON.stringify(parentRef.filteringPanel.selected))

    //get selected chart elements
    let date = e.data[e.selection.row + 1][0]

    filter.period.startDate = date
    filter.period.endDate = moment(date).endOf('month')

    //filter.period.startDate = moment(date).startOf('year')
    //filter.period.endDate =  moment(date).endOf('year')

    let description = `${this.$refs.chart2L.title} [Period: ${moment(
      date
    ).format('MM/DD/YY')}]`

    this.$store.dispatch('drilldown.save', {
      filter: filter,
      parent: this.$route.name,
      description: description,
      method: 'ChainPerformance.Details'
    })

    //open new page
    this.$router.push({
      name: 'Chart drilldown'
    })
  },
  drilldownChart2R: function (e, parentRef) {
    //let filter = this.$refs.filteringPanel.selected
    let filter = JSON.parse(JSON.stringify(parentRef.filteringPanel.selected))

    //get selected chart elements
    let date = e.data[e.selection.row + 1][0]

    filter.period.startDate = date
    filter.period.endDate = moment(date).endOf('month')

    //filter.period.startDate = moment(date).startOf('year')
    //filter.period.endDate =  moment(date).endOf('year')

    let description = `${this.$refs.chart2R.title} [Period: ${moment(
      date
    ).format('MM/DD/YY')}]`

    this.$store.dispatch('drilldown.save', {
      filter: filter,
      parent: this.$route.name,
      description: description,
      method: 'ChainPerformance.Details'
    })

    //open new page
    this.$router.push({
      name: 'Chart drilldown'
    })
  }
}
