var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', [_c('g-chart-custom', {
    ref: "chart3L",
    attrs: {
      "title": "In-chain product mix",
      "subtitle": _vm.charts.chart3L.subtitle,
      "type": "AreaChart",
      "loading": _vm.charts.chart3L.loading,
      "data": _vm.charts.chart3L.chartData,
      "options": _vm.charts.chart3L.chartOptions
    },
    on: {
      "chart-element-selected": function chartElementSelected($event) {
        return _vm.drilldownChart3L($event, _vm.parentRef);
      }
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('b-popover', {
          attrs: {
            "target": "chart3L-ddi",
            "triggers": "hover",
            "placement": "right"
          }
        }, [_c('span', [_vm._v("Chart drilldown available")])]), _c('i', {
          staticClass: "ml-2 fa fa-share fa-rotate-90",
          attrs: {
            "id": "chart3L-ddi"
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-col', [_c('g-chart-custom', {
    ref: "chart3R",
    attrs: {
      "title": "In-chain product mix",
      "subtitle": _vm.charts.chart3R.subtitle,
      "type": "AreaChart",
      "loading": _vm.charts.chart3R.loading,
      "data": _vm.charts.chart3R.chartData,
      "options": _vm.charts.chart3R.chartOptions
    },
    on: {
      "chart-element-selected": function chartElementSelected($event) {
        return _vm.drilldownChart3R($event, _vm.parentRef);
      }
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('b-popover', {
          attrs: {
            "target": "chart3R-ddi",
            "triggers": "hover",
            "placement": "right"
          }
        }, [_c('span', [_vm._v("Chart drilldown available")])]), _c('i', {
          staticClass: "ml-2 fa fa-share fa-rotate-90",
          attrs: {
            "id": "chart3R-ddi"
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }