<template>
  <b-row>
    <b-col>
      <g-chart-custom
        ref="chart2L"
        title="In-chain sales"
        :subtitle="charts.chart2L.subtitle"
        type="LineChart"
        :loading="charts.chart2L.loading"
        :data="charts.chart2L.chartData"
        :options="charts.chart2L.chartOptions"
        @chart-element-selected="drilldownChart2L($event, parentRef)"
      >
        <template #legend>
          <b-popover target="chart2L-ddi" triggers="hover" placement="right">
            <span>Chart drilldown available</span>
          </b-popover>
          <i id="chart2L-ddi" class="ml-2 fa fa-share fa-rotate-90" />
        </template>
      </g-chart-custom>
    </b-col>
    <b-col>
      <g-chart-custom
        ref="chart2R"
        title="In-chain sales"
        :subtitle="charts.chart2R.subtitle"
        type="LineChart"
        :loading="charts.chart2R.loading"
        :data="charts.chart2R.chartData"
        :options="charts.chart2R.chartOptions"
        @chart-element-selected="drilldownChart2R($event, parentRef)"
      >
        <template #legend>
          <b-popover target="chart2R-ddi" triggers="hover" placement="right">
            <span>Chart drilldown available</span>
          </b-popover>
          <i id="chart2R-ddi" class="ml-2 fa fa-share fa-rotate-90" />
        </template>
      </g-chart-custom>
    </b-col>
  </b-row>
</template>

<script>
import GChartCustom from '@/components/GChartCustom'

import charts from './charts'
import charts2LRMethods from './charts2LR.methods'

export default {
  props: {
    parentRef: Object
  },
  components: {
    GChartCustom
  },
  data () {
    return {
      charts
    }
  },
  methods: {
    ...charts2LRMethods
  }
}
</script>
