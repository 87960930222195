<template>
  <b-row>
    <b-col>
      <g-chart-custom
        ref="chart4L"
        :default-mode="charts.chart4L.isDefaultMode"
        title="Top selling products"
        :subtitle="charts.chart4L.subtitle"
        :type="charts.chart4L.type"
        :loading="charts.chart4L.loading"
        :data="charts.chart4L.chartData"
        :options="charts.chart4L.chartOptions"
      >
        <template #legend>
          <b-popover target="chart4L-help" triggers="hover" placement="right">
            <span v-html="popoverTemplate" />
          </b-popover>
          <i id="chart4L-help" class="fa fa-question-circle" />
        </template>
        <template #chartTopBar>
          <b-row align-h="between">
            <b-col>
              <b-pagination
                v-show="charts.chart4L.isDefaultMode"
                v-model="charts.chart4L.pagination.value"
                pills
                :hide-ellipsis="true"
                :total-rows="charts.chart4L.pagination.total"
                :per-page="10"
                @input="updateChart4L"
              />
            </b-col>
            <b-col class="text-right">
              <b-button-group>
                <b-button
                  class="mb-2"
                  variant="outline-primary"
                  @click="
                    charts.chart4L.isColumnType = false
                    charts.chart4L.type = 'PieChart'
                    charts.chart4L.isDefaultMode = true
                  "
                >
                  <font-awesome-icon icon="chart-pie" />
                </b-button>
                <b-button
                  class="mb-2"
                  variant="outline-primary"
                  @click="
                    charts.chart4L.isColumnType = true
                    charts.chart4L.type = 'BarChart'
                    charts.chart4L.isDefaultMode = true
                  "
                >
                  <font-awesome-icon icon="chart-bar" />
                </b-button>
                <b-button
                  class="mb-2"
                  variant="outline-primary"
                  @click="charts.chart4L.isDefaultMode = false"
                >
                  <font-awesome-icon icon="list" />
                </b-button>
              </b-button-group>
            </b-col>
          </b-row>
        </template>
        <template #table>
          <table-custom
            :name="chart4LTable.name"
            :loading="chart4LTable.isLoading"
            :data="chart4LTable.dataSet"
            :options="chart4LTable.options"
            @mounted="onChart4LTableMount"
          />
        </template>
        <template #chartBottomBar>
          <b-row align-h="between" v-show="charts.chart4L.isDefaultMode">
            <b-col class="text-left">
              <strong>Total sold:</strong> ${{
                charts.chart4L.totals.totalSold.toLocaleString(
                  'en-US',
                  'currency'
                )
              }}
              <br />
              <strong>Total sold selection:</strong> ${{
                charts.chart4L.totals.totalSoldSelection.toLocaleString(
                  'en-US',
                  'currency'
                )
              }}
            </b-col>
          </b-row>
        </template>
      </g-chart-custom>
    </b-col>

    <b-col>
      <g-chart-custom
        ref="chart4R"
        :default-mode="charts.chart4R.isDefaultMode"
        title="Top selling products"
        :subtitle="charts.chart4R.subtitle"
        :type="charts.chart4R.type"
        :loading="charts.chart4R.loading"
        :data="charts.chart4R.chartData"
        :options="charts.chart4R.chartOptions"
      >
        <template #legend>
          <b-popover target="chart4R-help" triggers="hover" placement="right">
            <span v-html="popoverTemplate" />
          </b-popover>
          <i id="chart4R-help" class="fa fa-question-circle" />
        </template>
        <template #chartTopBar>
          <b-row align-h="between">
            <b-col>
              <b-pagination
                v-show="charts.chart4R.isDefaultMode"
                v-model="charts.chart4R.pagination.value"
                pills
                :hide-ellipsis="true"
                :total-rows="charts.chart4R.pagination.total"
                :per-page="10"
                @input="updateChart4R"
              />
            </b-col>
            <b-col class="text-right">
              <b-button-group>
                <b-button
                  class="mb-2"
                  variant="outline-primary"
                  @click="
                    charts.chart4R.isColumnType = false
                    charts.chart4R.type = 'PieChart'
                    charts.chart4R.isDefaultMode = true
                  "
                >
                  <font-awesome-icon icon="chart-pie" />
                </b-button>
                <b-button
                  class="mb-2"
                  variant="outline-primary"
                  @click="
                    charts.chart4R.isColumnType = true
                    charts.chart4R.type = 'BarChart'
                    charts.chart4R.isDefaultMode = true
                  "
                >
                  <font-awesome-icon icon="chart-bar" />
                </b-button>
                <b-button
                  class="mb-2"
                  variant="outline-primary"
                  @click="charts.chart4R.isDefaultMode = false"
                >
                  <font-awesome-icon icon="list" />
                </b-button>
              </b-button-group>
            </b-col>
          </b-row>
        </template>
        <template #table>
          <table-custom
            :name="chart4RTable.name"
            :loading="chart4RTable.isLoading"
            :data="chart4RTable.dataSet"
            :options="chart4RTable.options"
            @mounted="onChart4RTableMount"
          />
        </template>
        <template #chartBottomBar>
          <b-row align-h="between" v-show="charts.chart4R.isDefaultMode">
            <b-col class="text-left">
              <strong>Total sold:</strong> ${{
                charts.chart4R.totals.totalSold.toLocaleString(
                  'en-US',
                  'currency'
                )
              }}
              <br />
              <strong>Total sold selection:</strong> ${{
                charts.chart4R.totals.totalSoldSelection.toLocaleString(
                  'en-US',
                  'currency'
                )
              }}
            </b-col>
          </b-row>
        </template>
      </g-chart-custom>
    </b-col>
  </b-row>
</template>

<script>
import GChartCustom from '@/components/GChartCustom'

import charts from './charts'
import charts4LRMethods from './charts4LR.methods'
import tables from './tables'

export default {
  components: {
    GChartCustom
  },
  data () {
    return {
      charts,
      chart4LTable: tables.chart4LTable,
      chart4RTable: tables.chart4RTable,
      popoverTemplate:
        '<ul> <li>Selection period: The user selected date range</li> </ul>'
    }
  },
  methods: {
    ...charts4LRMethods
  }
}
</script>
