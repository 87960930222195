var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12",
      "sm": "12"
    }
  }, [_c('b-row', [_c('b-col', [_c('h4', {
    staticClass: "float-left"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _vm._t("fullDataLink"), _c('clip-loader', {
    staticClass: "ml-3 mt-2",
    attrs: {
      "size": 14,
      "color": "#36D7B7",
      "loading": _vm.loading === undefined ? false : _vm.loading
    }
  })], 2)], 1), _c('b-row', [_c('b-col', [_vm._v(_vm._s(_vm.subtitle))])], 1)], 1)], 1), _c('b-row', [_c('b-col', [!_vm.loading && _vm.data.length > 0 ? _c('v-client-table', {
    ref: "table",
    attrs: {
      "data": _vm.data,
      "columns": _vm.columns,
      "name": 'custom_table',
      "options": _vm.options
    },
    on: {
      "row-click": _vm.onRowClick
    },
    scopedSlots: _vm._u([_vm._l(_vm.formatColumns, function (column) {
      return {
        key: column.name,
        fn: function fn(props) {
          return [_vm._v(" " + _vm._s((parseFloat(props.row[column.name]) || 0).toLocaleString('en-US', column.style)) + " ")];
        }
      };
    }), {
      key: "Actions",
      fn: function fn(props) {
        return _vm.showActions ? _c('div', {}, [_c('div', {
          staticClass: "btn-group"
        }, [_c('button', {
          staticClass: "btn btn-primary btn-sm",
          on: {
            "click": function click($event) {
              return _vm.viewItem(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "eye"
          }
        })], 1), _c('button', {
          staticClass: "btn btn-success btn-sm",
          on: {
            "click": function click($event) {
              return _vm.editItem(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "pencil-alt"
          }
        })], 1), _c('button', {
          staticClass: "btn btn-danger btn-sm",
          on: {
            "click": function click($event) {
              return _vm.deleteItem(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "trash"
          }
        })], 1)])]) : _vm._e();
      }
    }], null, true)
  }, [_c('div', {
    staticClass: "pb-3",
    attrs: {
      "slot": "afterTable"
    },
    slot: "afterTable"
  }, _vm._l(_vm.totals, function (item, index) {
    return _c('div', {
      key: "".concat(index)
    }, [_c('strong', [_vm._v("Total " + _vm._s(item.column) + ":")]), _vm._v(" " + _vm._s(item.value.toLocaleString('en-US', item.style)) + " ")]);
  }), 0)]) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }