var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', [_c('g-chart-custom', {
    ref: "chart2L",
    attrs: {
      "title": "In-chain sales",
      "subtitle": _vm.charts.chart2L.subtitle,
      "type": "LineChart",
      "loading": _vm.charts.chart2L.loading,
      "data": _vm.charts.chart2L.chartData,
      "options": _vm.charts.chart2L.chartOptions
    },
    on: {
      "chart-element-selected": function chartElementSelected($event) {
        return _vm.drilldownChart2L($event, _vm.parentRef);
      }
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('b-popover', {
          attrs: {
            "target": "chart2L-ddi",
            "triggers": "hover",
            "placement": "right"
          }
        }, [_c('span', [_vm._v("Chart drilldown available")])]), _c('i', {
          staticClass: "ml-2 fa fa-share fa-rotate-90",
          attrs: {
            "id": "chart2L-ddi"
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-col', [_c('g-chart-custom', {
    ref: "chart2R",
    attrs: {
      "title": "In-chain sales",
      "subtitle": _vm.charts.chart2R.subtitle,
      "type": "LineChart",
      "loading": _vm.charts.chart2R.loading,
      "data": _vm.charts.chart2R.chartData,
      "options": _vm.charts.chart2R.chartOptions
    },
    on: {
      "chart-element-selected": function chartElementSelected($event) {
        return _vm.drilldownChart2R($event, _vm.parentRef);
      }
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('b-popover', {
          attrs: {
            "target": "chart2R-ddi",
            "triggers": "hover",
            "placement": "right"
          }
        }, [_c('span', [_vm._v("Chart drilldown available")])]), _c('i', {
          staticClass: "ml-2 fa fa-share fa-rotate-90",
          attrs: {
            "id": "chart2R-ddi"
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }