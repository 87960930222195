import moment from 'moment'

export default {
  drawChart5L (response, filter) {
    this.charts.chart5L.loading = false

    if (response.length === 0) return

    this.charts.chart5L.rawData = response

    this.updateChart5L(filter)
  },
  updateChart5L (filter) {
    //let filter = this.$refs.filteringPanel.selected
    this.charts.chart5L.subtitle = `Sales in the period [${moment(
      filter.period.startDate
    ).format('YYYY-MM-DD')} - ${moment(filter.period.endDate).format(
      'YYYY-MM-DD'
    )}]`

    let headers = [
      [
        'Account',
        '$ Sold',
        {
          type: 'string',
          role: 'tooltip'
        }
      ]
    ]

    let metric = undefined

    metric = 'sales1'

    let data = [
      ...this.charts.chart5L.rawData.map(item => {
        const tooltip = `Account: ${
          item.accountname
        }\nTotal sales $: ${parseFloat(item['sales1']).toFixed(
          2
        )}\nBottles: ${parseFloat(item['bottles1']).toFixed(
          2
        )}\nAlc. amount: ${parseFloat(item['alc_amount1']).toFixed(2)}`

        return [
          `${item.accountname} (${item.sales1} - ${item.sales_pcnt1}%)`,
          parseFloat(item[metric] || 0),
          tooltip
        ]
      })
    ]

    //sort by sales desc
    data = data.sort(function (a, b) {
      return b[1] - a[1]
    })

    this.charts.chart5L.totals.totalSold = 0
    this.charts.chart5L.totals.totalSold = data
      .map(function (v) {
        return v[1]
      })
      .reduce((sum, x) => sum + x)

    this.charts.chart5L.pagination.total = data.length

    data = data.slice(
      this.charts.chart5L.pagination.value * 10 - 10,
      this.charts.chart5L.pagination.value * 10
    )

    this.charts.chart5L.totals.totalSoldSelection = 0
    this.charts.chart5L.totals.totalSoldSelection = data
      .map(function (v) {
        return v[1]
      })
      .reduce((sum, x) => sum + x)

    this.charts.chart5L.chartData = headers.concat(data)
  },
  onChart5LTableMount () {
    this.chart5LTable.dataSet = this.charts.chart5L.rawData.map(function (i) {
      let obj = {}

      obj['Account'] = i.accountname
      obj['$ Sold'] = i['sales1']
      obj['Bottles'] = parseFloat(i['bottles1'])
      obj['Amount of alcohol'] = parseFloat(i['alc_amount1'] || 0)

      return obj
    })
  },
  drawChart5R (response, filter) {
    this.charts.chart5R.loading = false

    if (response.length === 0) return

    this.charts.chart5R.rawData = response

    this.updateChart5R(filter)
  },
  updateChart5R (filter) {
    // let filter = this.$refs.filteringPanel.selected
    this.charts.chart5R.subtitle = `Sales in the period [${moment(
      filter.period.startDate
    ).format('YYYY-MM-DD')} - ${moment(filter.period.endDate).format(
      'YYYY-MM-DD'
    )}]`

    let headers = [
      [
        'Account',
        '$ Sold',
        {
          type: 'string',
          role: 'tooltip'
        }
      ]
    ]

    let metric = undefined

    metric = 'sales1'

    let data = [
      ...this.charts.chart5R.rawData.map(item => {
        const tooltip = `Account: ${
          item.accountname
        }\nTotal sales $: ${parseFloat(item['sales1']).toFixed(
          2
        )}\nBottles: ${parseFloat(item['bottles1']).toFixed(
          2
        )}\nAlc. amount: ${parseFloat(item['alc_amount1']).toFixed(2)}`

        return [
          `${item.accountname} (${item.sales1} - ${item.sales_pcnt1}%)`,
          parseFloat(item[metric] || 0),
          tooltip
        ]
      })
    ]

    //sort by sales desc
    data = data.sort(function (a, b) {
      return b[1] - a[1]
    })

    this.charts.chart5R.totals.totalSold = 0
    this.charts.chart5R.totals.totalSold = data
      .map(function (v) {
        return v[1]
      })
      .reduce((sum, x) => sum + x)

    this.charts.chart5R.pagination.total = data.length

    data = data.slice(
      this.charts.chart5R.pagination.value * 10 - 10,
      this.charts.chart5R.pagination.value * 10
    )

    this.charts.chart5R.totals.totalSoldSelection = 0
    this.charts.chart5R.totals.totalSoldSelection = data
      .map(function (v) {
        return v[1]
      })
      .reduce((sum, x) => sum + x)

    this.charts.chart5R.chartData = headers.concat(data)
  },
  onChart5RTableMount () {
    this.chart5RTable.dataSet = this.charts.chart5R.rawData.map(function (i) {
      let obj = {}

      obj['Account'] = i.accountname
      obj['$ Sold'] = i['sales1']
      obj['Bottles'] = parseFloat(i['bottles1'])
      obj['Amount of alcohol'] = parseFloat(i['alc_amount1'] || 0)

      return obj
    })
  }
}
