var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', [_c('g-chart-custom', {
    ref: "chart5L",
    attrs: {
      "default-mode": _vm.charts.chart5L.isDefaultMode,
      "title": "Top 100 accounts sales",
      "subtitle": _vm.charts.chart5L.subtitle,
      "type": _vm.charts.chart5L.type,
      "loading": _vm.charts.chart5L.loading,
      "data": _vm.charts.chart5L.chartData,
      "options": _vm.charts.chart5L.chartOptions
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('i', {
          staticClass: "fa fa-question-circle",
          attrs: {
            "id": "chart5L-help"
          }
        }), _c('b-popover', {
          attrs: {
            "target": "chart5L-help",
            "triggers": "hover",
            "placement": "right"
          }
        }, [_c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.popoverTemplate)
          }
        })])];
      },
      proxy: true
    }, {
      key: "chartTopBar",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart5L.isDefaultMode,
            expression: "charts.chart5L.isDefaultMode"
          }],
          attrs: {
            "lg": "6",
            "sm": "12",
            "md": "12"
          }
        }, [_c('b-pagination', {
          attrs: {
            "pills": "",
            "hide-ellipsis": true,
            "total-rows": _vm.charts.chart5L.pagination.total,
            "per-page": 10
          },
          on: {
            "input": _vm.updateChart5L
          },
          model: {
            value: _vm.charts.chart5L.pagination.value,
            callback: function callback($$v) {
              _vm.$set(_vm.charts.chart5L.pagination, "value", $$v);
            },
            expression: "charts.chart5L.pagination.value"
          }
        })], 1), _c('b-col', {
          staticClass: "text-right"
        }, [_c('b-button-group', [_c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart5L.isColumnType = false;
              _vm.charts.chart5L.type = 'PieChart';
              _vm.charts.chart5L.isDefaultMode = true;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-pie"
          }
        })], 1), _c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart5L.isColumnType = true;
              _vm.charts.chart5L.type = _vm.$isMobile ? 'ColumnChart' : 'BarChart';
              _vm.charts.chart5L.isDefaultMode = true;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-bar"
          }
        })], 1), _c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart5L.isDefaultMode = false;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "list"
          }
        })], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "table",
      fn: function fn() {
        return [_c('table-custom', {
          attrs: {
            "name": _vm.chart5LTable.name,
            "loading": _vm.chart5LTable.isLoading,
            "data": _vm.chart5LTable.dataSet,
            "options": _vm.chart5LTable.options
          },
          on: {
            "mounted": _vm.onChart5LTableMount
          }
        })];
      },
      proxy: true
    }, {
      key: "chartBottomBar",
      fn: function fn() {
        return [_c('b-row', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart5L.isDefaultMode,
            expression: "charts.chart5L.isDefaultMode"
          }],
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col', {
          staticClass: "text-left"
        }, [_c('strong', [_vm._v("Total sold:")]), _vm._v(" $" + _vm._s(_vm.charts.chart5L.totals.totalSold.toLocaleString('en-US', 'currency')) + " "), _c('br'), _c('strong', [_vm._v("Total sold selection:")]), _vm._v(" $" + _vm._s(_vm.charts.chart5L.totals.totalSoldSelection.toLocaleString('en-US', 'currency')) + " ")])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', [_c('g-chart-custom', {
    ref: "chart5R",
    attrs: {
      "default-mode": _vm.charts.chart5R.isDefaultMode,
      "title": "Top 100 accounts sales",
      "subtitle": _vm.charts.chart5R.subtitle,
      "type": _vm.charts.chart5R.type,
      "loading": _vm.charts.chart5R.loading,
      "data": _vm.charts.chart5R.chartData,
      "options": _vm.charts.chart5R.chartOptions
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('i', {
          staticClass: "fa fa-question-circle",
          attrs: {
            "id": "chart5R-help"
          }
        }), _c('b-popover', {
          attrs: {
            "target": "chart5R-help",
            "triggers": "hover",
            "placement": "right"
          }
        }, [_c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.popoverTemplate)
          }
        })])];
      },
      proxy: true
    }, {
      key: "chartTopBar",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart5R.isDefaultMode,
            expression: "charts.chart5R.isDefaultMode"
          }],
          attrs: {
            "lg": "6",
            "sm": "12",
            "md": "12"
          }
        }, [_c('b-pagination', {
          attrs: {
            "pills": "",
            "hide-ellipsis": true,
            "total-rows": _vm.charts.chart5R.pagination.total,
            "per-page": 10
          },
          on: {
            "input": _vm.updateChart5R
          },
          model: {
            value: _vm.charts.chart5R.pagination.value,
            callback: function callback($$v) {
              _vm.$set(_vm.charts.chart5R.pagination, "value", $$v);
            },
            expression: "charts.chart5R.pagination.value"
          }
        })], 1), _c('b-col', {
          staticClass: "text-right"
        }, [_c('b-button-group', [_c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart5R.isColumnType = false;
              _vm.charts.chart5R.type = 'PieChart';
              _vm.charts.chart5R.isDefaultMode = true;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-pie"
          }
        })], 1), _c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart5R.isColumnType = true;
              _vm.charts.chart5R.type = _vm.$isMobile ? 'ColumnChart' : 'BarChart';
              _vm.charts.chart5R.isDefaultMode = true;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-bar"
          }
        })], 1), _c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart5R.isDefaultMode = false;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "list"
          }
        })], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "table",
      fn: function fn() {
        return [_c('table-custom', {
          attrs: {
            "name": _vm.chart5RTable.name,
            "loading": _vm.chart5RTable.isLoading,
            "data": _vm.chart5RTable.dataSet,
            "options": _vm.chart5RTable.options
          },
          on: {
            "mounted": _vm.onChart5RTableMount
          }
        })];
      },
      proxy: true
    }, {
      key: "chartBottomBar",
      fn: function fn() {
        return [_c('b-row', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart5R.isDefaultMode,
            expression: "charts.chart5R.isDefaultMode"
          }],
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col', {
          staticClass: "text-left"
        }, [_c('strong', [_vm._v("Total sold:")]), _vm._v(" $" + _vm._s(_vm.charts.chart5R.totals.totalSold.toLocaleString('en-US', 'currency')) + " "), _c('br'), _c('strong', [_vm._v("Total sold selection:")]), _vm._v(" $" + _vm._s(_vm.charts.chart5R.totals.totalSoldSelection.toLocaleString('en-US', 'currency')) + " ")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }