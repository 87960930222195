var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', [_c('g-chart-custom', {
    ref: "chart6L",
    attrs: {
      "default-mode": _vm.charts.chart6L.isDefaultMode,
      "title": "Chains sales",
      "subtitle": _vm.charts.chart6L.subtitle,
      "type": _vm.charts.chart6L.type,
      "loading": _vm.charts.chart6L.loading,
      "data": _vm.charts.chart6L.chartData,
      "options": _vm.charts.chart6L.chartOptions
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('i', {
          staticClass: "fa fa-question-circle",
          attrs: {
            "id": "chart6L-help"
          }
        }), _c('b-popover', {
          attrs: {
            "target": "chart6L-help",
            "triggers": "hover",
            "placement": "right"
          }
        }, [_c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.popoverTemplate)
          }
        })])];
      },
      proxy: true
    }, {
      key: "chartTopBar",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart6L.isDefaultMode,
            expression: "charts.chart6L.isDefaultMode"
          }],
          attrs: {
            "lg": "6",
            "sm": "12",
            "md": "12"
          }
        }, [_c('b-pagination', {
          attrs: {
            "pills": "",
            "hide-ellipsis": true,
            "total-rows": _vm.charts.chart6L.pagination.total,
            "per-page": 10
          },
          on: {
            "input": _vm.updateChart6L
          },
          model: {
            value: _vm.charts.chart6L.pagination.value,
            callback: function callback($$v) {
              _vm.$set(_vm.charts.chart6L.pagination, "value", $$v);
            },
            expression: "charts.chart6L.pagination.value"
          }
        })], 1), _c('b-col', {
          staticClass: "text-right"
        }, [_c('b-button-group', [_c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart6L.isColumnType = false;
              _vm.charts.chart6L.type = 'PieChart';
              _vm.charts.chart6L.isDefaultMode = true;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-pie"
          }
        })], 1), _c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart6L.isColumnType = true;
              _vm.charts.chart6L.type = _vm.$isMobile ? 'ColumnChart' : 'BarChart';
              _vm.charts.chart6L.isDefaultMode = true;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-bar"
          }
        })], 1), _c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart6L.isDefaultMode = false;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "list"
          }
        })], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "table",
      fn: function fn() {
        return [_c('table-custom', {
          attrs: {
            "name": _vm.chart6LTable.name,
            "loading": _vm.chart6LTable.isLoading,
            "data": _vm.chart6LTable.dataSet,
            "options": _vm.chart6LTable.options
          },
          on: {
            "mounted": _vm.onChart6LTableMount
          }
        })];
      },
      proxy: true
    }, {
      key: "chartBottomBar",
      fn: function fn() {
        return [_c('b-row', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart6L.isDefaultMode,
            expression: "charts.chart6L.isDefaultMode"
          }],
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col', {
          staticClass: "text-left"
        }, [_c('strong', [_vm._v("Total sold:")]), _vm._v(" $" + _vm._s(_vm.charts.chart6L.totals.totalSold.toLocaleString('en-US', 'currency')) + " "), _c('br'), _c('strong', [_vm._v("Total sold selection:")]), _vm._v(" $" + _vm._s(_vm.charts.chart6L.totals.totalSoldSelection.toLocaleString('en-US', 'currency')) + " ")])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', [_c('g-chart-custom', {
    ref: "chart6R",
    attrs: {
      "default-mode": _vm.charts.chart6R.isDefaultMode,
      "title": "Chains sales",
      "subtitle": _vm.charts.chart6R.subtitle,
      "type": _vm.charts.chart6R.type,
      "loading": _vm.charts.chart6R.loading,
      "data": _vm.charts.chart6R.chartData,
      "options": _vm.charts.chart6R.chartOptions
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('i', {
          staticClass: "fa fa-question-circle",
          attrs: {
            "id": "chart6R-help"
          }
        }), _c('b-popover', {
          attrs: {
            "target": "chart6R-help",
            "triggers": "hover",
            "placement": "right"
          }
        }, [_c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.popoverTemplate)
          }
        })])];
      },
      proxy: true
    }, {
      key: "chartTopBar",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart6R.isDefaultMode,
            expression: "charts.chart6R.isDefaultMode"
          }],
          attrs: {
            "lg": "6",
            "sm": "12",
            "md": "12"
          }
        }, [_c('b-pagination', {
          attrs: {
            "pills": "",
            "hide-ellipsis": true,
            "total-rows": _vm.charts.chart6R.pagination.total,
            "per-page": 10
          },
          on: {
            "input": _vm.updateChart6R
          },
          model: {
            value: _vm.charts.chart6R.pagination.value,
            callback: function callback($$v) {
              _vm.$set(_vm.charts.chart6R.pagination, "value", $$v);
            },
            expression: "charts.chart6R.pagination.value"
          }
        })], 1), _c('b-col', {
          staticClass: "text-right"
        }, [_c('b-button-group', [_c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart6R.isColumnType = false;
              _vm.charts.chart6R.type = 'PieChart';
              _vm.charts.chart6R.isDefaultMode = true;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-pie"
          }
        })], 1), _c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart6R.isColumnType = true;
              _vm.charts.chart6R.type = _vm.$isMobile ? 'ColumnChart' : 'BarChart';
              _vm.charts.chart6R.isDefaultMode = true;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-bar"
          }
        })], 1), _c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart6R.isDefaultMode = false;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "list"
          }
        })], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "table",
      fn: function fn() {
        return [_c('table-custom', {
          attrs: {
            "name": _vm.chart6RTable.name,
            "loading": _vm.chart6RTable.isLoading,
            "data": _vm.chart6RTable.dataSet,
            "options": _vm.chart6RTable.options
          },
          on: {
            "mounted": _vm.onChart6RTableMount
          }
        })];
      },
      proxy: true
    }, {
      key: "chartBottomBar",
      fn: function fn() {
        return [_c('b-row', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart6R.isDefaultMode,
            expression: "charts.chart6R.isDefaultMode"
          }],
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col', {
          staticClass: "text-left"
        }, [_c('strong', [_vm._v("Total sold:")]), _vm._v(" $" + _vm._s(_vm.charts.chart6R.totals.totalSold.toLocaleString('en-US', 'currency')) + " "), _c('br'), _c('strong', [_vm._v("Total sold selection:")]), _vm._v(" $" + _vm._s(_vm.charts.chart6R.totals.totalSoldSelection.toLocaleString('en-US', 'currency')) + " ")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }