export default {
  table1: {
    loading: false,
    dataSet: [],
    onRowClick: {}
  },
  table2: {
    loading: false,
    dataSet: [],
    onRowClick: {},
    formatColumns: [
      {
        name: 'Sales',
        style: {
          style: 'currency',
          currency: 'USD'
        }
      }
    ],
    totalColumns: [
      {
        name: 'Sales',
        style: {
          style: 'currency',
          currency: 'USD'
        }
      }
    ]
  },
  chart4LTable: {
    name: 'custom_table_chain_performance_chart4L',
    isLoading: false,
    dataSet: [],
    options: {
      columns: ['Product', '$ Sold', 'Bottles', 'Amount of alcohol'],
      totalColumns: [
        {
          name: '$ Sold',
          alias: 'Sold',
          style: {
            style: 'currency',
            currency: 'USD'
          }
        }
      ],
      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,
      formatColumns: [
        {
          name: '$ Sold',
          style: {
            style: 'currency',
            currency: 'USD'
          },
          type: 'number'
        }
      ]
    }
  },
  chart4RTable: {
    name: 'custom_table_chain_performance_chart4R',
    isLoading: false,
    dataSet: [],
    options: {
      columns: ['Product', '$ Sold', 'Bottles', 'Amount of alcohol'],
      totalColumns: [
        {
          name: '$ Sold',
          alias: 'Sold',
          style: {
            style: 'currency',
            currency: 'USD'
          }
        }
      ],
      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,
      formatColumns: [
        {
          name: '$ Sold',
          style: {
            style: 'currency',
            currency: 'USD'
          },
          type: 'number'
        }
      ]
    }
  },
  chart5LTable: {
    name: 'custom_table_accouns_sales_chart5L',
    isLoading: false,
    dataSet: [],
    options: {
      columns: ['Account', '$ Sold', 'Bottles', 'Amount of alcohol'],
      totalColumns: [
        {
          name: '$ Sold',
          alias: 'Sold',
          style: {
            style: 'currency',
            currency: 'USD'
          }
        }
      ],
      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,
      formatColumns: [
        {
          name: '$ Sold',
          style: {
            style: 'currency',
            currency: 'USD'
          },
          type: 'number'
        }
      ]
    }
  },
  chart5RTable: {
    name: 'custom_table_accouns_sales_chart5R',
    isLoading: false,
    dataSet: [],
    options: {
      columns: ['Account', '$ Sold', 'Bottles', 'Amount of alcohol'],
      totalColumns: [
        {
          name: '$ Sold',
          alias: 'Sold',
          style: {
            style: 'currency',
            currency: 'USD'
          }
        }
      ],
      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,
      formatColumns: [
        {
          name: '$ Sold',
          style: {
            style: 'currency',
            currency: 'USD'
          },
          type: 'number'
        }
      ]
    }
  },
  chart6LTable: {
    name: 'custom_table_chains_sales_chart6L',
    isLoading: false,
    dataSet: [],
    options: {
      columns: ['Chain', '$ Sold', 'Bottles', 'Amount of alcohol'],
      totalColumns: [
        {
          name: '$ Sold',
          alias: 'Sold',
          style: {
            style: 'currency',
            currency: 'USD'
          }
        }
      ],
      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,
      formatColumns: [
        {
          name: '$ Sold',
          style: {
            style: 'currency',
            currency: 'USD'
          },
          type: 'number'
        }
      ]
    }
  },
  chart6RTable: {
    name: 'custom_table_chains_sales_chart6R',
    isLoading: false,
    dataSet: [],
    options: {
      columns: ['Chain', '$ Sold', 'Bottles', 'Amount of alcohol'],
      totalColumns: [
        {
          name: '$ Sold',
          alias: 'Sold',
          style: {
            style: 'currency',
            currency: 'USD'
          }
        }
      ],
      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,
      formatColumns: [
        {
          name: '$ Sold',
          style: {
            style: 'currency',
            currency: 'USD'
          },
          type: 'number'
        }
      ]
    }
  }
}
