import moment from 'moment'

export default {
  drawChart1L: function (response, filter) {
    if (response.length === 0) return

    this.charts.chart1L.subtitle = `Points of sale over time in the period [${moment(
      filter.period.startDate
    ).format('YYYY-MM-DD')} - ${moment(filter.period.endDate).format(
      'YYYY-MM-DD'
    )}]`

    //chart 1
    let headers = [['Year', 'Accounts', 'Markets']]

    let data = [
      ...response.map(item => {
        return [
          new Date(moment(item.date).format('MM/DD/YY')),
          parseFloat(item.accountcount || 0),
          parseFloat(item.marketcount || 0)
        ]
      })
    ]

    this.charts.chart1L.chartData = headers.concat(data)
  },
  drilldownChart1L: function (e, parentRef) {
    //let filter = this.$refs.filteringPanel.selected
    let filter = JSON.parse(JSON.stringify(parentRef.filteringPanel.selected))

    //get selected chart elements
    let date = e.data[e.selection.row + 1][0]

    filter.period.startDate = date
    filter.period.endDate = moment(date).endOf('month')

    //let result = this.$helpers.parsePeriodFromString(date)
    //filter.period.startDate = result.startDate
    //filter.period.endDate = result.endDate

    let description = `${this.$refs.chart1L.title} [Period: ${moment(
      date
    ).format('MM/DD/YY')}]`

    this.$store.dispatch('drilldown.save', {
      filter: filter,
      parent: this.$route.name,
      description: description,
      method: 'ChainPerformance.Details'
    })

    //open new page
    this.$router.push({
      name: 'Chart drilldown'
    })
  },

  drawChart1R (response, filter) {
    if (response.length === 0) return

    // let filter = JSON.parse(JSON.stringify(fp))
    // filter.period = filter.period2

    this.charts.chart1R.subtitle = `Points of sale over time in the period [${moment(
      filter.period.startDate
    ).format('YYYY-MM-DD')} - ${moment(filter.period.endDate).format(
      'YYYY-MM-DD'
    )}]`

    //chart 1
    let headers = [['Year', 'Accounts', 'Markets']]

    let data = [
      ...response.map(item => {
        return [
          new Date(moment(item.date).format('MM/DD/YY')),
          parseFloat(item.accountcount || 0),
          parseFloat(item.marketcount || 0)
        ]
      })
    ]

    this.charts.chart1R.chartData = headers.concat(data)
  },
  drilldownChart1R: function (e, parentRef) {
    //let filter = this.$refs.filteringPanel.selected
    let filter = JSON.parse(JSON.stringify(parentRef.filteringPanel.selected))

    //get selected chart elements
    let date = e.data[e.selection.row + 1][0]

    filter.period.startDate = date
    filter.period.endDate = moment(date).endOf('month')

    //let result = this.$helpers.parsePeriodFromString(date)
    //filter.period.startDate = result.startDate
    //filter.period.endDate = result.endDate

    let description = `${this.$refs.chart1R.title} [Period: ${moment(
      date
    ).format('MM/DD/YY')}]`

    this.$store.dispatch('drilldown.save', {
      filter: filter,
      parent: this.$route.name,
      description: description,
      method: 'ChainPerformance.Details'
    })

    //open new page
    this.$router.push({
      name: 'Chart drilldown'
    })
  }
}
