var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('h2', [_vm._v("Chain Performance - Distribution")]), _c('br'), _c('b-row', [_c('b-col', [_c('filtering-panel', {
    ref: "filteringPanel",
    attrs: {
      "mode": "server",
      "dataset-name": "accounts/activity/chain-performance",
      "show-labels": true,
      "compact": true,
      "districts": _vm.filteringPanel.districts,
      "filters": _vm.filteringPanel.filters,
      "load-dictionaries": _vm.loadDictionaries,
      "loaded": _vm.filteringPanel.loaded
    },
    on: {
      "change": _vm.onFilteringPanelChange,
      "search": _vm.getData,
      "reset": _vm.onFilteringPanelReset
    }
  })], 1)], 1), _c('hr'), _c('br'), _c('br'), _c('br'), _c('br'), _c('hr'), _c('Charts1LR', {
    attrs: {
      "parentRef": _vm.$refs
    }
  }), _c('hr'), _c('br'), _c('br'), _c('br'), _c('br'), _c('hr'), _c('Charts2LR', {
    attrs: {
      "parentRef": _vm.$refs
    }
  }), _c('hr'), _c('br'), _c('br'), _c('br'), _c('br'), _c('hr'), _c('Charts3LR', {
    attrs: {
      "parentRef": _vm.$refs
    }
  }), _c('hr'), _c('br'), _c('br'), _c('br'), _c('br'), _c('hr'), _c('Charts4LR'), _c('hr'), _c('br'), _c('br'), _c('br'), _c('br'), _c('hr'), _c('Charts5LR'), _c('hr'), _c('br'), _c('br'), _c('br'), _c('br'), _c('hr'), _c('Charts6LR'), _c('hr'), _c('br'), _c('br'), _c('br'), _c('br'), _c('hr'), _c('b-row', [_c('b-col', [_c('b-tabs', {
    attrs: {
      "content-class": "mt-3",
      "justified": ""
    },
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Chain locations"
    }
  }, [_c('table-custom-old', {
    ref: "table1",
    attrs: {
      "title": "",
      "subtitle": '',
      "loading": _vm.tables.table1.loading,
      "data": _vm.tables.table1.dataSet
    },
    on: {
      "row-click": _vm.tables.table1.onRowClick
    }
  })], 1), _c('b-tab', {
    attrs: {
      "title": "Sales by month"
    }
  }, [_c('table-custom-old', {
    ref: "table2",
    attrs: {
      "title": "",
      "subtitle": '',
      "loading": _vm.tables.table2.loading,
      "data": _vm.tables.table2.dataSet,
      "format-columns": _vm.tables.table2.formatColumns,
      "total-columns": _vm.tables.table2.totalColumns
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }