var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', [_c('g-chart-custom', {
    ref: "chart4L",
    attrs: {
      "default-mode": _vm.charts.chart4L.isDefaultMode,
      "title": "Top selling products",
      "subtitle": _vm.charts.chart4L.subtitle,
      "type": _vm.charts.chart4L.type,
      "loading": _vm.charts.chart4L.loading,
      "data": _vm.charts.chart4L.chartData,
      "options": _vm.charts.chart4L.chartOptions
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('b-popover', {
          attrs: {
            "target": "chart4L-help",
            "triggers": "hover",
            "placement": "right"
          }
        }, [_c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.popoverTemplate)
          }
        })]), _c('i', {
          staticClass: "fa fa-question-circle",
          attrs: {
            "id": "chart4L-help"
          }
        })];
      },
      proxy: true
    }, {
      key: "chartTopBar",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col', [_c('b-pagination', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart4L.isDefaultMode,
            expression: "charts.chart4L.isDefaultMode"
          }],
          attrs: {
            "pills": "",
            "hide-ellipsis": true,
            "total-rows": _vm.charts.chart4L.pagination.total,
            "per-page": 10
          },
          on: {
            "input": _vm.updateChart4L
          },
          model: {
            value: _vm.charts.chart4L.pagination.value,
            callback: function callback($$v) {
              _vm.$set(_vm.charts.chart4L.pagination, "value", $$v);
            },
            expression: "charts.chart4L.pagination.value"
          }
        })], 1), _c('b-col', {
          staticClass: "text-right"
        }, [_c('b-button-group', [_c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart4L.isColumnType = false;
              _vm.charts.chart4L.type = 'PieChart';
              _vm.charts.chart4L.isDefaultMode = true;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-pie"
          }
        })], 1), _c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart4L.isColumnType = true;
              _vm.charts.chart4L.type = 'BarChart';
              _vm.charts.chart4L.isDefaultMode = true;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-bar"
          }
        })], 1), _c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart4L.isDefaultMode = false;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "list"
          }
        })], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "table",
      fn: function fn() {
        return [_c('table-custom', {
          attrs: {
            "name": _vm.chart4LTable.name,
            "loading": _vm.chart4LTable.isLoading,
            "data": _vm.chart4LTable.dataSet,
            "options": _vm.chart4LTable.options
          },
          on: {
            "mounted": _vm.onChart4LTableMount
          }
        })];
      },
      proxy: true
    }, {
      key: "chartBottomBar",
      fn: function fn() {
        return [_c('b-row', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart4L.isDefaultMode,
            expression: "charts.chart4L.isDefaultMode"
          }],
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col', {
          staticClass: "text-left"
        }, [_c('strong', [_vm._v("Total sold:")]), _vm._v(" $" + _vm._s(_vm.charts.chart4L.totals.totalSold.toLocaleString('en-US', 'currency')) + " "), _c('br'), _c('strong', [_vm._v("Total sold selection:")]), _vm._v(" $" + _vm._s(_vm.charts.chart4L.totals.totalSoldSelection.toLocaleString('en-US', 'currency')) + " ")])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', [_c('g-chart-custom', {
    ref: "chart4R",
    attrs: {
      "default-mode": _vm.charts.chart4R.isDefaultMode,
      "title": "Top selling products",
      "subtitle": _vm.charts.chart4R.subtitle,
      "type": _vm.charts.chart4R.type,
      "loading": _vm.charts.chart4R.loading,
      "data": _vm.charts.chart4R.chartData,
      "options": _vm.charts.chart4R.chartOptions
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('b-popover', {
          attrs: {
            "target": "chart4R-help",
            "triggers": "hover",
            "placement": "right"
          }
        }, [_c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.popoverTemplate)
          }
        })]), _c('i', {
          staticClass: "fa fa-question-circle",
          attrs: {
            "id": "chart4R-help"
          }
        })];
      },
      proxy: true
    }, {
      key: "chartTopBar",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col', [_c('b-pagination', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart4R.isDefaultMode,
            expression: "charts.chart4R.isDefaultMode"
          }],
          attrs: {
            "pills": "",
            "hide-ellipsis": true,
            "total-rows": _vm.charts.chart4R.pagination.total,
            "per-page": 10
          },
          on: {
            "input": _vm.updateChart4R
          },
          model: {
            value: _vm.charts.chart4R.pagination.value,
            callback: function callback($$v) {
              _vm.$set(_vm.charts.chart4R.pagination, "value", $$v);
            },
            expression: "charts.chart4R.pagination.value"
          }
        })], 1), _c('b-col', {
          staticClass: "text-right"
        }, [_c('b-button-group', [_c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart4R.isColumnType = false;
              _vm.charts.chart4R.type = 'PieChart';
              _vm.charts.chart4R.isDefaultMode = true;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-pie"
          }
        })], 1), _c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart4R.isColumnType = true;
              _vm.charts.chart4R.type = 'BarChart';
              _vm.charts.chart4R.isDefaultMode = true;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-bar"
          }
        })], 1), _c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart4R.isDefaultMode = false;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "list"
          }
        })], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "table",
      fn: function fn() {
        return [_c('table-custom', {
          attrs: {
            "name": _vm.chart4RTable.name,
            "loading": _vm.chart4RTable.isLoading,
            "data": _vm.chart4RTable.dataSet,
            "options": _vm.chart4RTable.options
          },
          on: {
            "mounted": _vm.onChart4RTableMount
          }
        })];
      },
      proxy: true
    }, {
      key: "chartBottomBar",
      fn: function fn() {
        return [_c('b-row', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart4R.isDefaultMode,
            expression: "charts.chart4R.isDefaultMode"
          }],
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col', {
          staticClass: "text-left"
        }, [_c('strong', [_vm._v("Total sold:")]), _vm._v(" $" + _vm._s(_vm.charts.chart4R.totals.totalSold.toLocaleString('en-US', 'currency')) + " "), _c('br'), _c('strong', [_vm._v("Total sold selection:")]), _vm._v(" $" + _vm._s(_vm.charts.chart4R.totals.totalSoldSelection.toLocaleString('en-US', 'currency')) + " ")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }