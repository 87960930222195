<template>
  <div class="animated fadeIn">
    <b-card>
      <h2>Chain Performance - Distribution</h2>
      <br />
      <b-row>
        <b-col>
          <filtering-panel
            ref="filteringPanel"
            mode="server"
            dataset-name="accounts/activity/chain-performance"
            :show-labels="true"
            :compact="true"
            :districts="filteringPanel.districts"
            :filters="filteringPanel.filters"
            :load-dictionaries="loadDictionaries"
            :loaded="filteringPanel.loaded"
            @change="onFilteringPanelChange"
            @search="getData"
            @reset="onFilteringPanelReset"
          />
        </b-col>
      </b-row>
      <hr />
      <br />
      <br />
      <br />
      <br />
      <hr />
      <Charts1LR :parentRef="$refs" />
      <hr />
      <br />
      <br />
      <br />
      <br />
      <hr />
      <Charts2LR :parentRef="$refs" />
      <hr />
      <br />
      <br />
      <br />
      <br />
      <hr />
      <Charts3LR :parentRef="$refs" />
      <hr />
      <br />
      <br />
      <br />
      <br />
      <hr />
      <Charts4LR />
      <hr />
      <br />
      <br />
      <br />
      <br />
      <hr />
      <Charts5LR />
      <hr />
      <br />
      <br />
      <br />
      <br />
      <hr />
      <Charts6LR />
      <hr />
      <br />
      <br />
      <br />
      <br />
      <hr />
      <b-row>
        <b-col>
          <b-tabs content-class="mt-3" justified v-model="activeTab">
            <b-tab title="Chain locations">
              <table-custom-old
                ref="table1"
                title=""
                :subtitle="''"
                :loading="tables.table1.loading"
                :data="tables.table1.dataSet"
                @row-click="tables.table1.onRowClick"
              />
            </b-tab>
            <b-tab title="Sales by month">
              <table-custom-old
                ref="table2"
                title=""
                :subtitle="''"
                :loading="tables.table2.loading"
                :data="tables.table2.dataSet"
                :format-columns="tables.table2.formatColumns"
                :total-columns="tables.table2.totalColumns"
              />
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import TableCustomOld from '@/components/TableCustomOld'
import FilteringPanel from '@/components/FilteringPanel'

import fp from './filters'
import charts from './charts'
import tables from './tables'
import chart1LRMethods from './charts1LR.methods'
import chart2LRMethods from './charts2LR.methods'
import chart3LRMethods from './charts3LR.methods'
import chart4LRMethods from './charts4LR.methods'
import chart5LRMethods from './charts5LR.methods'
import chart6LRMethods from './charts6LR.methods'
import Charts1LR from './charts1LR.vue'
import Charts2LR from './charts2LR.vue'
import Charts3LR from './charts3LR.vue'
import Charts4LR from './charts4LR.vue'
import Charts5LR from './charts5LR.vue'
import Charts6LR from './charts6LR.vue'

export default {
  name: 'ChainPerformance',
  components: {
    FilteringPanel,
    TableCustomOld,
    Charts1LR,
    Charts2LR,
    Charts3LR,
    Charts4LR,
    Charts5LR,
    Charts6LR
  },
  data: function () {
    return {
      isLoading: false,
      activeTab: 0,

      filteringPanel: fp.filteringPanel,
      charts: charts,
      tables: tables
    }
  },
  async mounted () {},
  methods: {
    ...chart1LRMethods,
    ...chart2LRMethods,
    ...chart3LRMethods,
    ...chart4LRMethods,
    ...chart5LRMethods,
    ...chart6LRMethods,

    onFilteringPanelReset () {
      this.$refs.filteringPanel.resetFilters({
        resetStorage: true
      })
    },
    onFilteringPanelChange () {},
    async loadDictionaries () {
      let self = this

      let response = await self.$api.get('dictionaries/territories')

      let territories = response.map(u => ({
        id: u.ID,
        label: u.Name
      }))

      response = await self.$api.get('dictionaries/chains')

      let chains = response.map(u => ({
        id: u.ID,
        label: u.Name
      }))

      self.filteringPanel.districts = await self.$api.post(
        'dictionaries/countries/mapped',
        {
          ignore_restrictions: false
        }
      )

      let countries = self.$helpers.getDistinctArray(
        self.filteringPanel.districts,
        'country',
        'country',
        'id',
        'label',
        'iso_code'
      )
      let states = self.$helpers.getDistinctArray(
        self.filteringPanel.districts,
        'state',
        'state',
        'id',
        'label',
        'iso_code'
      )

      self.filteringPanel.filters.find(
        f => f.name === 'territory'
      ).options = territories
      self.filteringPanel.filters.find(
        f => f.name === 'country'
      ).options = countries
      self.filteringPanel.filters.find(f => f.name === 'state').options = states
      self.filteringPanel.filters.find(f => f.name === 'chain').options = chains

      this.getData()
    },
    getData: function () {
      let filter = JSON.parse(
        JSON.stringify(this.$refs.filteringPanel.selected)
      )
      let filter2 = JSON.parse(
        JSON.stringify(this.$refs.filteringPanel.selected)
      )
      filter2.period = filter.period2

      this.charts.chart1L.loading = true
      this.charts.chart2L.loading = true
      this.charts.chart3L.loading = true
      this.charts.chart4L.loading = true
      this.charts.chart5L.loading = true
      this.charts.chart6L.loading = true

      this.charts.chart1R.loading = true
      this.charts.chart2R.loading = true
      this.charts.chart3R.loading = true
      this.charts.chart4R.loading = true
      this.charts.chart5R.loading = true
      this.charts.chart6R.loading = true

      this.$api.data
        .requestReportData('chain-performance', filter)
        .then(response => {
          this.charts.chart1L.loading = false
          this.charts.chart2L.loading = false
          this.charts.chart3L.loading = false

          if (response.length === 0) return

          this.drawChart1L(response, filter)
          this.drawChart2L(response, filter)
          this.drawChart3L(response, filter)
        })

      this.$api.data
        .requestReportData('chain-performance', filter2)
        .then(response => {
          this.charts.chart1R.loading = false
          this.charts.chart2R.loading = false
          this.charts.chart3R.loading = false

          if (response.length === 0) return

          this.drawChart1R(response, filter2)
          this.drawChart2R(response, filter2)
          this.drawChart3R(response, filter2)
        })

      this.$api.data
        .requestReportData('products/overview/sales', filter)
        .then(response => {
          this.charts.chart4L.loading = false

          if (response.length === 0) return

          this.drawChart4L(response, filter)
        })

      this.$api.data
        .requestReportData('products/overview/sales', filter2)
        .then(response => {
          this.charts.chart4R.loading = false

          if (response.length === 0) return

          this.drawChart4R(response, filter2)
        })

      this.$api.data
        .requestReportData('products/details/sales-by-accounts', filter)
        .then(response => {
          this.charts.chart5L.loading = false

          if (response.length === 0) return

          this.drawChart5L(response, filter)
        })

      this.$api.data
        .requestReportData('products/details/sales-by-accounts', filter2)
        .then(response => {
          this.charts.chart5R.loading = false

          if (response.length === 0) return

          this.drawChart5R(response, filter2)
        })

      this.$api.data
        .requestReportData('products/details/sales-by-chains', filter)
        .then(response => {
          this.charts.chart6L.loading = false

          if (response.length === 0) return

          this.drawChart6L(response, filter)
        })

      this.$api.data
        .requestReportData('products/details/sales-by-chains', filter2)
        .then(response => {
          this.charts.chart6R.loading = false

          if (response.length === 0) return

          this.drawChart6R(response, filter2)
        })

      const onRowClick = function (event) {
        /*
        this.$store.dispatch(
          "filterPanel.selected.accountname",
          event.row["Account Name"]
        );
        */

        this.$form.prepareDrillDownFilters(
          this.$refs.filteringPanel,
          event.row['Account Name']
        )
        this.$router.push({
          name: 'Account overview'
        })
      }
      this.tables.table1.onRowClick = onRowClick.bind(this)

      this.drawTable1(filter)

      this.drawTable2(filter)
    },
    drawTable1: async function () {
      this.tables.table1.loading = true

      this.$api.data
        .requestReportData(
          'chain-performance-locations',
          this.$refs.filteringPanel.selected
        )
        .then(response => {
          this.tables.table1.loading = false
          this.tables.table1.dataSet = response
        })
    },
    drawTable2: async function () {
      this.tables.table2.loading = true

      this.$api.data['ChainPerformance.MonthlySales'](
        this.$refs.filteringPanel.selected
      ).then(response => {
        this.tables.table2.loading = false
        this.tables.table2.dataSet = response
      })
    }
  }
}
</script>
