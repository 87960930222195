import { isMobile } from 'mobile-device-detect'

export default {
  chart1L: {
    loading: false,
    chartData: null,
    chartOptions: {
      height: 350,
      chartArea: {
        width: '80%',
        height: '65%'
      },
      title: '',
      legend: {
        position: 'bottom'
      },
      vAxes: {
        0: {
          title: 'Account Count'
        },
        1: {
          title: 'Market Count'
        }
      },
      hAxis: {
        //format: 'MMM, YY',
      },
      seriesType: 'bars',
      series: {
        0: {
          targetAxisIndex: 0
        },
        1: {
          targetAxisIndex: 1
        }
      }
    }
  },
  chart1R: {
    loading: false,
    chartData: null,
    chartOptions: {
      height: 350,
      chartArea: {
        width: '80%',
        height: '65%'
      },
      title: '',
      legend: {
        position: 'bottom'
      },
      vAxes: {
        0: {
          title: 'Account Count'
        },
        1: {
          title: 'Market Count'
        }
      },
      hAxis: {
        //format: 'MMM, YY',
      },
      seriesType: 'bars',
      series: {
        0: {
          targetAxisIndex: 0
        },
        1: {
          targetAxisIndex: 1
        }
      }
    }
  },
  chart2L: {
    loading: false,
    chartData: null,
    chartOptions: {
      height: 350,
      chartArea: {
        width: '80%',
        height: '65%'
      },
      title: '',
      legend: {
        position: 'bottom'
      },
      vAxes: {
        0: {
          title: 'Total Sales'
        },
        1: {
          title: 'Average Sales'
        }
      },
      hAxis: {
        //format: 'MMM, YY',
      },
      series: {
        0: {
          targetAxisIndex: 0
        },
        1: {
          targetAxisIndex: 1
        }
      }
    }
  },
  chart2R: {
    loading: false,
    chartData: null,
    chartOptions: {
      height: 350,
      chartArea: {
        width: '80%',
        height: '65%'
      },
      title: '',
      legend: {
        position: 'bottom'
      },
      vAxes: {
        0: {
          title: 'Total Sales'
        },
        1: {
          title: 'Average Sales'
        }
      },
      hAxis: {
        //format: 'MMM, YY',
      },
      series: {
        0: {
          targetAxisIndex: 0
        },
        1: {
          targetAxisIndex: 1
        }
      }
    }
  },
  chart3L: {
    loading: false,
    chartData: null,
    chartOptions: {
      height: 300,
      chartArea: {
        width: '80%',
        height: '80%'
      },
      isStacked: 'percent',
      hAxis: {
        // format: 'MMM, YY',
      }
    }
  },
  chart3R: {
    loading: false,
    chartData: null,
    chartOptions: {
      height: 300,
      chartArea: {
        width: '80%',
        height: '80%'
      },
      isStacked: 'percent',
      hAxis: {
        // format: 'MMM, YY',
      }
    }
  },
  chart4L: {
    type: 'PieChart',
    pagination: {
      value: 1,
      total: 0
    },
    isDefaultMode: true,
    isColumnType: false,
    rawData: [],
    emptyStates: [],
    loading: false,
    subtitle: '',
    chartData: null,
    chartOptions: {
      height: 500,
      is3D: true,
      //pieSliceText: 'label',
      chartArea: {
        width: isMobile ? '100%' : '90%',
        height: isMobile ? '70%' : '90%'
      },
      legend: {
        position: isMobile ? 'top' : 'right',
        alignment: 'center',
        maxLines: 10
      },
      vAxis: {
        textStyle: {
          fontSize: 10
        }
      }
    },
    totals: {
      totalSold: 0,
      totalSoldSelection: 0
    }
  },
  chart4R: {
    type: 'PieChart',
    pagination: {
      value: 1,
      total: 0
    },
    isDefaultMode: true,
    isColumnType: false,
    rawData: [],
    emptyStates: [],
    loading: false,
    subtitle: '',
    chartData: null,
    chartOptions: {
      height: 500,
      is3D: true,
      //pieSliceText: 'label',
      chartArea: {
        width: isMobile ? '100%' : '90%',
        height: isMobile ? '70%' : '90%'
      },
      legend: {
        position: isMobile ? 'top' : 'right',
        alignment: 'center',
        maxLines: 10
      },
      vAxis: {
        textStyle: {
          fontSize: 10
        }
      }
    },
    totals: {
      totalSold: 0,
      totalSoldSelection: 0
    }
  },
  chart5L: {
    type: isMobile ? 'ColumnChart' : 'BarChart',
    pagination: {
      value: 1,
      total: 0
    },
    isBarChart: false,
    isDefaultMode: true,
    isColumnType: false,
    rawData: [],
    emptyStates: [],
    loading: false,
    subtitle: '',
    chartData: null,
    chartOptions: {
      height: 500,
      is3D: true,
      //pieSliceText: 'label',
      chartArea: {
        width: isMobile ? '90%' : '65%',
        height: isMobile ? '80%' : '90%'
      },
      legend: {
        position: 'none'
      },
      hAxis: {
        textStyle: {
          fontSize: 12
        }
      }
    },
    totals: {
      totalSold: 0,
      totalSoldSelection: 0
    }
  },
  chart5R: {
    type: isMobile ? 'ColumnChart' : 'BarChart',
    pagination: {
      value: 1,
      total: 0
    },
    isBarChart: false,
    isDefaultMode: true,
    isColumnType: false,
    rawData: [],
    emptyStates: [],
    loading: false,
    subtitle: '',
    chartData: null,
    chartOptions: {
      height: 500,
      is3D: true,
      //pieSliceText: 'label',
      chartArea: {
        width: isMobile ? '90%' : '65%',
        height: isMobile ? '80%' : '90%'
      },
      legend: {
        position: 'none'
      },
      hAxis: {
        textStyle: {
          fontSize: 12
        }
      }
    },
    totals: {
      totalSold: 0,
      totalSoldSelection: 0
    }
  },
  chart6L: {
    type: isMobile ? 'ColumnChart' : 'BarChart',
    pagination: {
      value: 1,
      total: 0
    },
    isBarChart: false,
    isDefaultMode: true,
    isColumnType: false,
    rawData: [],
    emptyStates: [],
    loading: false,
    subtitle: '',
    chartData: null,
    chartOptions: {
      height: 500,
      is3D: true,
      //pieSliceText: 'label',
      chartArea: {
        width: isMobile ? '90%' : '65%',
        height: isMobile ? '80%' : '90%'
      },
      legend: {
        position: 'left'
      },
      hAxis: {
        textStyle: {
          fontSize: 12
        }
      }
    },
    totals: {
      totalSold: 0,
      totalSoldSelection: 0
    }
  },
  chart6R: {
    type: isMobile ? 'ColumnChart' : 'BarChart',
    pagination: {
      value: 1,
      total: 0
    },
    isBarChart: false,
    isDefaultMode: true,
    isColumnType: false,
    rawData: [],
    emptyStates: [],
    loading: false,
    subtitle: '',
    chartData: null,
    chartOptions: {
      height: 500,
      is3D: true,
      //pieSliceText: 'label',
      chartArea: {
        width: isMobile ? '90%' : '65%',
        height: isMobile ? '80%' : '90%'
      },
      legend: {
        position: 'left'
      },
      hAxis: {
        textStyle: {
          fontSize: 12
        }
      }
    },
    totals: {
      totalSold: 0,
      totalSoldSelection: 0
    }
  }
}
