<template>
  <div>
    <b-row>
      <b-col lg="12" sm="12">
        <b-row>
          <b-col>
            <h4 class="float-left">
              {{ title }}
            </h4>
            <slot name="fullDataLink" />
            <clip-loader
              class="ml-3 mt-2"
              :size="14"
              color="#36D7B7"
              :loading="loading === undefined ? false : loading"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>{{ subtitle }}</b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <v-client-table
          v-if="!loading && data.length > 0"
          ref="table"
          :data="data"
          :columns="columns"
          :name="'custom_table'"
          :options="options"
          @row-click="onRowClick"
        >
          <template
            v-for="column in formatColumns"
            :slot="column.name"
            slot-scope="props"
          >
            {{
              (parseFloat(props.row[column.name]) || 0).toLocaleString(
                'en-US',
                column.style
              )
            }}
          </template>

          <!--  <span slot="Spent" slot-scope="props" >${{(parseFloat(props.row['Spent']) || 0).toLocaleString('en-US')}}</span> -->

          <div v-if="showActions" slot="Actions" slot-scope="props">
            <div class="btn-group">
              <button
                class="btn btn-primary btn-sm"
                @click="viewItem(props.row.ID)"
              >
                <font-awesome-icon icon="eye" />
              </button>
              <button
                class="btn btn-success btn-sm"
                @click="editItem(props.row.ID)"
              >
                <font-awesome-icon icon="pencil-alt" />
              </button>
              <button
                class="btn btn-danger btn-sm"
                @click="deleteItem(props.row.ID)"
              >
                <font-awesome-icon icon="trash" />
              </button>
            </div>
          </div>

          <div slot="afterTable" class="pb-3">
            <div v-for="(item, index) in totals" :key="`${index}`">
              <strong>Total {{ item.column }}:</strong>
              {{ item.value.toLocaleString('en-US', item.style) }}
            </div>
          </div>
        </v-client-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment'

const compare = {
  float: function (a, b, ascending) {
    let val1 = 0
    let val2 = 0

    if (typeof a === 'number') {
      val1 = a
    } else {
      val1 = a ? parseFloat(a.replace('$', '').replace(',', '')) : 0
    }

    if (typeof a === 'number') {
      val2 = b
    } else {
      val2 = b ? parseFloat(b.replace('$', '').replace(',', '')) : 0
    }

    if (ascending) return val1 >= val2 ? 1 : -1
    return val1 <= val2 ? 1 : -1
  },
  date: function (a, b, ascending) {
    let dateA = new Date(a)
    let dateB = new Date(b)
    if (ascending) return dateA >= dateB ? 1 : -1
    return dateA <= dateB ? 1 : -1
  },
  month: function (a, b, ascending) {
    if (!a || !b) return false

    let m1 = parseInt(moment().month(a).format('M'))
    let m2 = parseInt(moment().month(b).format('M'))

    if (ascending) return m1 >= m2 ? -1 : 1
    return m1 <= m2 ? -1 : 1
  }
}

export default {
  name: 'TableCustomOld',
  components: {},
  /*
        props: {
            title:{
                type: String,
                default: ''
            },
            subtitle:{
                type: String,
                default: ''
            },
            data:{
                type: Array,
                default: function () {  return [] }
            },
            footer:{
                type: String,
                default: ''
            },
            filter:{
                type: Array,
                default: function () {  return [] }
            },
            loading:{
                type: Boolean,
                default: false
            },
            perPage:{
                type: Number,
                default: 10
            },
            formatColumns:{
                type: Array,
                default: function () {  return [] }
            },
            showFull: {
                type: Boolean,
                default: false
            }
        },*/
  props: {
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    data: { type: Array, default: () => [] },
    footer: { type: String, default: '' },
    filterable: { type: String, default: '' },
    loading: { type: Boolean, default: false },
    perPage: { type: String, default: '' },
    disablePerPageDropdown: { type: Boolean, default: false },
    formatColumns: { type: Array, default: () => [] },
    footerHeadings: { type: String, default: '' },
    showFullLink: { type: Boolean, default: false },
    totalColumns: { type: Array, default: () => [] },
    showActions: { type: Boolean, default: false }
  },
  data: function () {
    return {
      dataSet: [],
      columns: [],
      totals: [],
      options: {
        filterByColumn: this.filterable ? this.filterable : false,
        //filterable: this.filterable ? this.filterable : false,
        filterable: false, //wilbe added dynamically
        pagination: this.footer ? this.footer : false,
        customSorting: {},
        perPage: this.perPage ? this.perPage : 100,
        perPageValues:
          this.perPage && !this.disablePerPageDropdown
            ? [10, 25, 50, 100, 500, 1000]
            : [],
        saveState: true,
        storage: 'session',
        // skin: 'table',
        footerHeadings: this.footerHeadings ? this.footerHeadings : false,
        //resizableColumns: true,
        summary: 'summary'
      }
    }
  },

  mounted () {},
  watch: {
    data: function (newVal) {
      this.drawTable(newVal)
    }
  },
  methods: {
    onRowClick: function (event) {
      this.$emit('row-click', event)
    },
    drawTable: function (data) {
      if (!data.length) return

      let o = data.find(o => o !== {})

      delete o.__ob__

      this.columns = Object.getOwnPropertyNames(o)

      if (this.showActions) this.columns.push('Actions')

      const parseTypeOld = value => {
        if (moment(value, 'YYYY/MM/DD', true).isValid()) return 'date'
        if (!isNaN(parseFloat(value.replace('$', '').replace(',', ''))))
          return 'float'
        return 'string'
      }

      const parseType = value => {
        if (moment(value, 'YYYY/MM/DD', true).isValid()) return 'date'

        const stringValue = String(value)

        if (!isNaN(parseFloat(stringValue.replace('$', '').replace(',', ''))))
          return 'float'

        return 'string'
      }

      this.totals = []

      this.columns.forEach(column => {
        //find first not empty value

        let value = ''
        for (let idx in data) {
          if (!data[idx][column]) continue

          value = data[idx][column]
          break
        }

        //let value = data[0][column]
        if (parseType(value) === 'float') {
          this.options.customSorting[column] = function (ascending) {
            return function (a, b) {
              return compare.float(a[column], b[column], ascending)
            }
          }
        }

        if (parseType(value) === 'date')
          this.options.customSorting[column] = function (ascending) {
            return function (a, b) {
              return compare.date(a[column], b[column], ascending)
            }
          }

        if (moment(value, 'MMMM').isValid())
          this.options.customSorting[column] = function (ascending) {
            return function (a, b) {
              return compare.month(a[column], b[column], ascending)
            }
          }

        //count totals
        //if (this.totalColumns && this.totalColumns.includes(column)) {
        if (this.totalColumns) {
          let col = this.totalColumns.find(c => c.name === column)

          if (this.totalColumns && col) {
            let _sum = data.sum(column)

            let total = {
              column: column,
              value: _sum,
              style: col.style
            }

            this.totals.push(total)
          }
        }
      })

      this.options.filterable = this.filterable

      this.dataSet = data
    },
    viewItem (id) {
      this.$emit('view-item', id)
    },
    editItem (id) {
      this.$emit('edit-item', id)
    },
    deleteItem (id) {
      this.$emit('delete-item', id)
    }
  }
}
</script>

<style>
.VuePagination__count {
  display: none;
}

table {
  overflow: auto;
}

.VueTables__table tr {
  cursor: pointer;
}

.inactive {
  text-decoration: 'line-through';
}

#full-data-link {
  padding: 5px;
  font-size: 1.5em;
  cursor: pointer;
}
</style>
